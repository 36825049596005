import React, { useEffect } from 'react';
import './Record.css';
import '../App.css';
import Carousel from './Carousel.js';

function Record() {

    // useEffect(() => {
    //     const video = document.getElementById('background-video');
    
    //     // Adjust the video settings as needed
    //     video.setAttribute('autoplay', true);
    //     video.setAttribute('loop', true);
    //     video.setAttribute('muted', true);
    //   }, []);


  return (
    <div className='record-container'>
        {/* <video id="background-video" className="video-background" autoPlay loop muted>
        <source src="./videos/video-1.mp4" type="video/mp4" />
        </video> */}
        
        <div className='record-header'>
            <p>See My Record Transactions</p>
        </div>
        <div className='record-body'>
            <Carousel timer={7000} infinite
            recordsList={["Queenstown EA sold record of $1.26M in 3 weeks with a COV of $100k!", 
            "Newly MOP Sembawang flat sold at $582k in 5 days!", 
            "5 room flat in Redhill rented out at record $5700 in 1 week, highest rental in the block!",
            "EM at Bukit Panjang sold at record $830k in 2 weeks!", 
            "Semi-D at Hillview rented out at record $9000 in 1 week, surpassing $6500 rent of neighbouring properties!", 
            "Successfully negotiated a significant increase of rent from $5200 to $7300 for corner terrace at Potong Pasir!",
            "Teban Garden 5 room flat rented out at record breaking rental $3500!",
            "Vintage EA flat in Choa Chu Kang North sold at noteworthy price of $713k!",
            "Original and vintage 4-room HDB flat in Ang Mo Kio set a new record, fetching an impressive $500,000 in just 3 days!",
            "Rented out a One + Study apartment at Guillemard Road for a remarkable $3000!",
            "Two-bedroom condominium apartment at Twin Vew rented out for $4250 monthly, an increase in rent of $1050!",
            "Helped my buyers swiftly secure a spacious 4-room HDB next to the MRT and Hillion Mall, negotiated $15k in savings for my happy client!"
        ]}
            >
                <img src='images/records/record-1.jpg' alt='record-img'/>
                <img src='images/records/record-2.jpg' alt='record-img'/>
                <img src='images/records/record-3.jpg' alt='record-img'/>
                <img src='images/records/record-4.jpg' alt='record-img'/>
                <img src='images/records/record-5.jpg' alt='record-img'/>
                <img src='images/records/record-6.jpg' alt='record-img'/>
                <img src='images/records/record-7.jpg' alt='record-img'/>
                <img src='images/records/record-8.jpg' alt='record-img'/>
                <img src='images/records/record-9.jpg' alt='record-img'/>
                <img src='images/records/record-10.jpg' alt='record-img'/>
                <img src='images/records/record-11.jpg' alt='record-img'/>
                <img src='images/records/record-12.png' alt='record-img'/>
            </Carousel>
        </div>
    </div>
  )
}

export default Record